import { render, staticRenderFns } from "./AllTransactions.vue?vue&type=template&id=12d7335d&scoped=true"
import script from "./AllTransactions.vue?vue&type=script&lang=js"
export * from "./AllTransactions.vue?vue&type=script&lang=js"
import style0 from "./AllTransactions.vue?vue&type=style&index=0&id=12d7335d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12d7335d",
  null
  
)

export default component.exports